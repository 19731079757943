@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply text-dark;
  }
}

.finder [class*="-control"] {
  @apply border-0 drop-shadow-sm shadow shadow-slate-100 h-12;
}

.finder [class*="-indicatorSeparator"] {
  @apply bg-white;
}

.intl-tel-input .flag-container {
  position: relative;
  flex: 1;
  @apply pl-4;
}
.intl-tel-input .form-control {
  padding-left: 1px !important;
}

.sharerus {
  @apply relative w-8 h-8 z-[8];
}
.sharerus :is(.btn, .link) {
  @apply bg-white rounded-full w-8 h-8 flex justify-center items-center;
}
.btn {
  @apply absolute cursor-pointer z-20;
}
.sharerus.active .link {
  @apply shadow-sm shadow-zinc-700;
}
.item {
  @apply absolute transition-transform delay-150;
}
.sharerus.active .item {
  @apply scale-150;
}

.sharerus.active .item:nth-child(1) {
  @apply -translate-x-6 translate-y-16;
}

.sharerus.active .item:nth-child(2) {
  @apply -translate-x-14 translate-y-4;
}

.sharerus.active .item:nth-child(3) {
  @apply -translate-x-12 -translate-y-10;
}

.sharerus.active .item:nth-child(4) {
  @apply translate-x-1 -translate-y-16;
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.92);
  top: 0;
  left: 0;

  @apply opacity-0 ease-in transition-opacity -z-10;
}
.overlay.show {
  @apply opacity-100 z-20;
}
